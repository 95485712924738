//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

// gamingaff: #0889AC; 
// bonuswelcome: #5B92FF; 
// deally: #5C17E2; 
// forex: #5B92FF;
// mymonsteraffiliates: #cc66ff

$primary: #5B92FF; 

